$primary-color: #ffffff;
$secondary-color: black;
$tertiary-color: #83cff8;

body {
  background: $primary-color;
}

.container {
  width: 30%;
  height: 90vh;
  transform: translate(15%, 15%);
  overflow: visible;
}
h1,
h2 {
  text-transform: uppercase;

  span {
    width: 100%;
    float: left;
    color: $tertiary-color;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    opacity: 0;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
}
h1 span {
  font-size: 400%;
  animation-delay: 0.6s;
  line-height: 90%;
  padding-top: 5%;
  animation-fill-mode: forwards;
  transform: translateY(-50px);
  animation-name: titleAnimation;

  &:first-child {
    animation-delay: 0.7s;
  }

  &:last-child {
    color: $secondary-color;
    animation-delay: 0.5s;
  }
}

h2 {
  font-size: 25px;

  span {
    animation-delay: 1.5s;
    transform: translateY(0);
    animation-name: titleAnimation2;
    animation-fill-mode: forwards;

    &:first-child {
      color: $secondary-color;
      animation-delay: 1.6s;
    }

    &:last-child {
      animation-delay: 1.4s;
    }
  }
}

.usechrome {
  font-size: 10px;
  color: #fff;
  font-family: helvetica, arial;
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  left: 0;
}

@keyframes titleAnimation {
  0% {
    transform: translateY(-50px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  50% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
}
@keyframes titleAnimation2 {
  0% {
    transform: translateY(0);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  50% {
    transform: translateY(60px);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(60px);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    height: 82vh;
    transform: translate(15%, 19%);
    h1 {
      span {
        font-size: 40px;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .container {
    h2 {
      span {
        font-size: 100%;
      }
    }
  }
}
